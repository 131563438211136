
import React, { useState } from 'react';
import axios  from "axios";
import * as am5 from "@amcharts/amcharts5";
 import * as am5map from "@amcharts/amcharts5/map";
 import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
 import  am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";

const Home=()=>{
    //const [loc, Setloc] = useState('');
    const [pin,Setpin]=useState([]);
    const [locCount,SetlocCount]=useState([]);
    let datas;
    
    const ValueChange=(evt)=>{
        SetlocCount([]);

        evt.preventDefault();
        let formData = new FormData();
        formData.append('data', evt.target.value)
      // axios.post('http://api.adortatechnologies.com/token/index.php', {
        //     axios.post('http://localhost/Gmb/', {
        //     d: evt.target.value,
        //   })
        
        axios({
            method: 'post',
            url: 'http://api.adortatechnologies.com/token/index.php',
            data: formData,
        })
        .then(function (response) {
            let l=JSON.parse(response.data);
            
            let location=l.locationDrivingDirectionMetrics[0].topDirectionSources[0].regionCounts;
            for(let i=0;i < location.length; i++){
                const pincode='/'+location[i].label;
                pin.push(pincode);
                axios.get(
                    'https://api.postalpincode.in/pincode'+pin[i]
                )
                .then(function (response) {
                    // const data1= locationarry.filter(checkAdult );
                    // function checkAdult(age) {
                    //       return age !== response.data[0].PostOffice[0].Name;
                    // }
                    // locationarry.push(response.data[0].PostOffice[0].Name);
                    let count=location[i].count;
                    let latitude=location[i].latlng.latitude;
                    let longitude=location[i].latlng.longitude;
                    datas={
                        location:response.data[0].PostOffice[0].Name+" "+count,
                        latitude:latitude.toFixed(2),
                        longitude:longitude.toFixed(2),
                    
                    }
                    locCount.push(datas);
                    })
                .catch(function (error) {
                        console.log(error);
                });
                        // pincode code ended
            }
            setTimeout(()=>{
                handleMap(locCount);
            }, 500);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const handleMap=(locCount) => {
        console.log(locCount);
                let root = am5.Root.new("chartdiv");
                // Set themes
                // https://www.amcharts.com/docs/v5/concepts/themes/
                root.setThemes([
                    am5themes_Animated.new(root)
                ]);
                // Create the map chart
                // https://www.amcharts.com/docs/v5/charts/map-chart/
                let chart = root.container.children.push(
                  am5map.MapChart.new(root, {
                    panX: "rotateX",
                    panY: "none",
                    projection: am5map.geoMercator(),    
                  })
                );
                let cont = chart.children.push(
                  am5.Container.new(root, {
                    layout: root.horizontalLayout,
                    x: 20,
                    y: 40
                  })
                );
                // Add labels and controls
                cont.children.push(
                  am5.Label.new(root, {
                    centerY: am5.p50,
                    text: "Map"
                  })
                );
                let switchButton = cont.children.push(
                  am5.Button.new(root, {
                    themeTags: ["switch"],
                    centerY: am5.p50,
                    icon: am5.Circle.new(root, {
                      themeTags: ["icon"]
                    })
                  })
                );
                switchButton.on("active", function () {
                  if (!switchButton.get("active")) {
                    chart.set("projection", am5map.geoMercator());
                    chart.set("panY", "translateY");
                    chart.set("rotationY", 0);
                    
                    backgroundSeries.mapPolygons.template.set("fillOpacity", 0);
                  } else {
                    chart.set("projection", am5map.geoOrthographic());
                    chart.set("panY", "rotateY");
                    backgroundSeries.mapPolygons.template.set("fillOpacity", 0.1);
                  }
                });
                cont.children.push(
                  am5.Label.new(root, {
                    centerY: am5.p50,
                    text: "Globe"
                  })
                );
                // Create series for background fill
                // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/#Background_polygon
                let backgroundSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {}));
                backgroundSeries.mapPolygons.template.setAll({
                  fill: root.interfaceColors.get("alternativeBackground"),
                  fillOpacity: 0,
                  strokeOpacity: 0
                });
                // Add background polygon
                // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/#Background_polygon
                backgroundSeries.data.push({
                    geometry: am5map.getGeoRectangle(90, 180, -90, -180)
                });
                // Create main polygon series for countries
                // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
                let polygonSeries = chart.series.push(
                    am5map.MapPolygonSeries.new(root, {
                        geoJSON: am5geodata_worldLow
                    })
                );
                // Create line series for trajectory lines
                // https://www.amcharts.com/docs/v5/charts/map-chart/map-line-series/
                let lineSeries = chart.series.push(am5map.MapLineSeries.new(root, {}));
                lineSeries.mapLines.template.setAll({
                    stroke: root.interfaceColors.get("alternativeBackground"),
                    strokeOpacity: 0.3
                });
                // Create point series for markers
                // https://www.amcharts.com/docs/v5/charts/map-chart/map-point-series/
                let pointSeries = chart.series.push(am5map.MapPointSeries.new(root, {}));
                pointSeries.bullets.push(function () {
                    let circle = am5.Circle.new(root, {
                        radius: 4,
                        tooltipY: 0,
                        fill: am5.color(0xffba00),
                        stroke: root.interfaceColors.get("background"),
                        strokeWidth: 2,
                        tooltipText: "{title}"
                    });
                    return am5.Bullet.new(root, {
                        sprite: circle
                    });
                });
                let cities = locCount
                for (var i = 0; i < cities.length; i++) {
                  let city = cities[i];
                  addCity(city.longitude, city.latitude, city.location);
                }
                function addCity(longitude, latitude, title) {
                  pointSeries.data.push({
                    geometry: { type: "Point", coordinates: [longitude, latitude] },
                    title: title
                  });
                }
                // Make stuff animate on load
                chart.appear(1000, 100);
                return () => {
                  root.dispose();
                }
               
            }
    return(
        <div className="container">
            <div className="row">
                <h1>Hello from Get Axios</h1>
                <div >
                <form>
                    <select onChange={ValueChange} name="data">
                        <option defaultValue>--choose --</option>
                        <option value="SEVEN">1 Week</option>
                        <option value="THIRTY">1 Month</option>
                        <option value="NINETY">1 Quater</option>
                    </select>

                </form>
                <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div> 
                </div>
                {
                    locCount.map((data, i) => {
                        return (
                            <div key={i} className="col-sm-3">
                                
                                <div className="grid">
                                    pincode:{data.location} <br />
                                    <p>  count: {data.count}</p>
                                </div>
                            </div>

                        );
                    })

                }
            </div>
        </div>
        
    )
}
export default Home;