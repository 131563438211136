import {BrowserRouter as Router,Route,Switch ,Link } from "react-router-dom";
import About from "./About";
import Home from "./Home";
function App() {
  return (
    <Router>
    <div className="container-fluid">
        <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
            <Link to="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">

                <span className="fs-4"> ROUTING</span>
            </Link>

            <ul className="nav nav-pills">
                <li className="nav-item">
                    <Link to="/" className="nav-link active" aria-current="page">Home</Link>
                </li>
                <li className="nav-item">
                    <Link to="/about" className="nav-link">About</Link>
                </li>
            </ul>
        </header>
    </div>
    <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/home" component={Home} />
        <Route path="/about" component={About} />
    </Switch>
</Router>
  );
}

export default App;
